<template>
  <div v-if="videoPlay" class="intro__video">
    <video
      v-if="returnVideo === 'intro-demo.mp4'"
      autoplay
      playsinline
      muted
      @ended="videoEnded"
      :src="require(`../../assets/Images/${returnVideo}`)"
    ></video>
    <video
      v-if="false"
      autoplay
      playsinline
      muted
      @ended="videoEnded"
      src="../../assets/Images/intro-demo.mp4"
    ></video>
    <video
      v-if="returnVideo === ''"
      autoplay
      playsinline
      muted
      @ended="videoEnded"
      src="../../assets/Images/intro-mercedes.mp4"
    ></video>
  </div>
  <div v-else class="login__main" :style="backgroundStyles">
    <div
      class="login__container"
      :class="{ 'login__container--transform': this.loginContainerTransform }"
    >
      <div class="login__block">
        <img
          v-if="returnLogo"
          class="login__logo"
          :src="require(`../../assets/Images/${returnLogo}`)"
        />
        <img
          v-if="false"
          class="login__logo"
          src="../../assets/Images/logo-dealer-demo.webp"
        />
        <img
          v-if="false"
          class="login__logo"
          src="../../assets/Images/ics.webp"
        />
        <img
          v-if="false"
          class="login__logo"
          src="../../assets/Images/nobile.webp"
        />
        <img
          v-if="false"
          class="login__logo"
          src="../../assets/Images/duda.webp"
        />
        <h2 class="login__header">{{ $t("login") }}</h2>
        <p class="login__text">
          {{ $t("step") }} {{ step }} {{ $t("outOf") }} 2
        </p>
        <p v-if="!loginIsValid" class="login__text balance">
          {{ $t("loginText") }}
        </p>
        <label v-if="!loginIsValid" class="login__label"
          >{{ $t("numberType") }}
          <input
            class="login__input"
            type="tel"
            id="number"
            name="phone"
            v-model="login"
            required
            :style="inputStyles"
            @keyup.enter="enteredLogin"
            @input="checkPhoneInputValue"
            placeholder="Wpisz"
          />
        </label>
        <label v-if="getError" class="login__label--error">
          {{ $t("wrongLogin") }}
        </label>
        <label v-if="getTooManyAttempts" class="login__label--error">
          {{ $t("tooManyAttempts") }}
        </label>
        <button
          :class="{
            'login__button--black': returnColor === '',
            'login__button--bordo': returnColor === '#801C62',
          }"
          @click="enteredLogin"
          v-if="!loginIsValid && !hideLoginButton"
        >
          {{ $t("next") }}
        </button>
        <LoginAuthorizationComponent
          v-if="loginIsValid && !hideLoginButton"
          @stepChange="stepChange"
        ></LoginAuthorizationComponent>
        <svg v-if="step === 1" class="csc__logo">
          <image href="../../assets/Icons/csc_logo_RGB.svg"></image>
        </svg>
      </div>
      <div class="login__image">
        <img
          v-if="returnLoginImage"
          :src="require(`../../assets/Images/${returnLoginImage}`)"
        />
        <img v-if="false" src="../../assets/Images/login-image-demo.webp" />
        <img v-if="false" src="../../assets/Images/login-image-duda.webp" />
        <img
          v-if="returnLoginImage === ''"
          src="../../assets/Images/login-image-mercedes.webp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "../../store";
import httpActions from "../../plugins/httpActionService";
import LoginAuthorizationComponent from "./LoginAuthorizationComponent.vue";

export default {
  components: {
    LoginAuthorizationComponent,
  },
  data() {
    return {
      login: "",
      videoPlay: true,
      step: 1,
      loginContainerTransform: false,
      isLoginProcessing: false,
    };
  },
  computed: {
    loginIsValid() {
      return useStore().goAnotherStep;
    },
    hideLoginButton() {
      return useStore().hideLoginButton;
    },

    inputStyles() {
      const length = this.login.length;
      switch (true) {
        case length > 12:
          return {
            "letter-spacing": "0.1rem",
            "font-size": "1rem",
          };
        case length > 10:
          return {
            "letter-spacing": "0.3rem",
            "font-size": "1.2rem",
          };
        case length > 8:
          return {
            "letter-spacing": "0.4rem",
            "font-size": "1.5rem",
          };
        case length > 6:
          return {
            "letter-spacing": "0.5rem",
            "font-size": "1.5rem",
          };
        case length > 4:
          return {
            "letter-spacing": "0.6rem",
            "font-size": "1.5rem",
          };
        default:
          return {
            "letter-spacing": "0.6rem",
            "font-size": "1.5rem",
          };
      }
    },

    returnLogo() {
      return useStore().getAssets;
    },

    returnLoginImage() {
      return useStore().getLoginPageImage;
    },

    returnVideo() {
      return useStore().getVideo;
    },

    returnBackground() {
      return useStore().getBackground;
    },

    backgroundStyles() {
      if (this.returnBackground === "bg-demo.webp") {
        return {
          background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${require("../../assets/Images/bg-demo.webp")})`,
          "background-repeat": "no-repeat",
          "background-size": "cover",
        };
      } else {
        return {
          background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${require("../../assets/Images/bg-mercedes.webp")})`,
          "background-repeat": "no-repeat",
          "background-size": "cover",
        };
      }
    },

    returnColor() {
      return useStore().getColor;
    },

    getError() {
      return useStore().loginError;
    },

    getTooManyAttempts() {
      return useStore().tooManyAttempts;
    },
  },
  methods: {
    checkPhoneInputValue() {
      useStore().set("login", this.login);
    },

    async enteredLogin() {
      if (!this.isLoginProcessing) {
        if (this.login.includes("@")) {
          useStore().set("loginMethodMail", true);
        } else {
          useStore().set("loginMethodMail", false);
        }
        this.isLoginProcessing = true;
        useStore().set("hideLoginButton", true);
        await httpActions.enteredLogin();
        this.isLoginProcessing = false;
      }
    },

    videoEnded() {
      this.videoPlay = false;
      setTimeout(() => {
        this.loginContainerTransform = true;
      }, 50);
    },
    stepChange(newValue) {
      this.step = newValue;
    },
  },
  beforeUnmount() {
    useStore().set("goAnotherStep", false);
    this.loginContainerTransform = false;
  },
  watch: {
    step(newValue) {
      if (newValue === 2) {
        useStore().set("loginError", false);
        useStore().set("tooManyAttempts", false);
      }
    },
  },
};
</script>
