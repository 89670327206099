<template>
  <div class="chat" :class="{ 'chat-hidden': !chat, 'chat-shown-small': chat && small, 'chat-shown-big': chat && !small }" @click.self="handleChat('open')">
    <div class="chat__navbar" v-if="chat">
      <div class="chat__navbar--block">
        <img src="../../assets/Icons/chat.svg" @click="handleChat('close')" />
        <span class="chat__navbar--block-dealer">Nazwa Dealera</span>
      </div>
      <div class="chat__navbar--block">
        <img v-if="small" src="../../assets/Icons/bigger.svg" @click="small = false" />
        <img v-if="!small" src="../../assets/Icons/smaller.svg" @click="small = true" />
        <img src="../../assets/Icons/close.svg" @click="handleChat('close')" />
      </div>
    </div>
    <div class="chat__main" v-if="chat">
      <div class="chat__main--messages" ref="messageBox">
        <div v-for="(message, index) in messages" :key="index" :class="{ 'chat__main--messages-user': message.isFromUser, 'chat__main--messages-dealer': !message.isFromUser }">
          <div v-if="message.isFromUser">Twoja wiadomość - {{ message.date }} - {{ message.minutes }}</div>
          <div v-if="message.isFromUser">{{ message.text }}</div>
        </div>
      </div>
      <div class="chat__main--input">
        <input placeholder="Wpisz wiadomość" v-model="currentMessage" @keyup.enter="sendMessage" />
        <img src="../../assets/Icons/send.svg" @click="sendMessage" />
      </div>
    </div>
    <img v-if="!chat" src="../../assets/Icons/chat.svg" @click.self="handleChat('open')" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      chat: false,
      small: true,
      currentMessage: null,
      messages: [],
    };
  },
  methods: {
    handleChat(func) {
      this.chat = true ? func === "open" : (this.chat = false);
    },
    sendMessage() {
      const date = new Date();
      if (this.currentMessage && this.currentMessage.trim() !== "") {
        const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`;
        const formattedTime = `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
        this.messages.push({
          text: this.currentMessage.trim(),
          isFromUser: true,
          date: formattedDate,
          minutes: formattedTime,
        });
      }
      this.currentMessage = null;
      this.$nextTick(() => {
        const messageBox = this.$refs.messageBox;
        if (messageBox) {
          messageBox.scrollTop = messageBox.scrollHeight;
        }
      })
    },
  },
};
</script>
