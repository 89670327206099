<template>
  <div class="configuration__wrapper">
    <ul v-if="isDataLoaded" class="configuration__list">
      <li class="configuration__element" v-for="(item, index) in configurationItems.filter((item) => item.detail)" :key="index">
        <div class="element__block">
          <svg>
            <image :href="item.iconHref"></image>
          </svg>
          <p v-if="item.info" class="element__info">{{ item.info }}</p>
          <p v-if="item.detail" class="element__detail">
            {{ capitalizeFirstLetter(item.detail) }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from "../../store";
import { capitalizeFirstLetter } from "../../plugins/helpers";

export default {
  data() {
    return {
      configurationItems: [
        {
          iconHref: require("../../assets/Icons/ico_mb_silnik_02.svg"),
          info: "Silnik",
          detail: "",
        },
        {
          iconHref: require("../../assets/Icons/ico_mb_skrzynia.svg"),
          info: "Skrzynia biegów",
          detail: "",
        },
        {
          iconHref: require("../../assets/Icons/ico_mb_pojemnosc_02.svg"),
          info: "Pojemność silnika",
          detail: "",
        },
        {
          iconHref: require("../../assets/Icons/ico_mb_moc.svg"),
          info: "Moc silnika",
          detail: "",
        },
        {
          iconHref: require("../../assets/Icons/ico_mb_zasieg.svg"),
          info: "Zasięg",
          detail: "",
        },
        {
          iconHref: require("../../assets/Icons/ico_mb_pojemnosc_baterii.svg"),
          info: "Pojemność baterii",
          detail: "",
        },
      ],
      isDataLoaded: false,
    };
  },
  computed: {
    mainOffer() {
      return useStore().getMainOffer;
    },
  },
  methods: {
    capitalizeFirstLetter,
    getData() {
      if (this.mainOffer[0]) {
        const vehicleData = this.mainOffer[0];
          this.configurationItems.forEach((item, index) => {
            switch (index) {
              case 0:
                item.detail = vehicleData.fuelType;
                break;
              case 1:
                item.detail = vehicleData.gearboxType;
                break;
              case 2:
                if (vehicleData.fuelType === "elektryczny") {
                  item.detail = null;
                } else {
                  item.detail = vehicleData.engineCapacity ? vehicleData.engineCapacity + "cm³" : null;
                }
                break;
              case 3:
                item.detail = vehicleData.power ? vehicleData.power + "KM" : null;
                break;
              case 4:
                if (vehicleData.fuelType === "elektryczny") {
                  item.detail = vehicleData.electricRange ? vehicleData.electricRange + "km" : null;
                } else if (vehicleData.fuelType === "hybryda") {
                  item.detail = null;
                } else if (vehicleData.fuelType !== "benzyna" && vehicleData.fuelType !== "diesel") {
                  item.detail = vehicleData.electricRange ? vehicleData.electricRange + "km" : null;
                } else {
                  item.detail = null;
                }
                break;
              case 5:
                if (vehicleData.fuelType === "elektryczny") {
                  item.detail = vehicleData.electricCapacity ? vehicleData.electricCapacity + "kWh" : null;
                } else if (vehicleData.fuelType === "hybryda") {
                  item.detail = vehicleData.electricCapacity ? vehicleData.electricCapacity + "kWh" : null;
                } else if (vehicleData.fuelType !== "benzyna" && vehicleData.fuelType !== "diesel") {
                  item.detail = vehicleData.electricCapacity ? vehicleData.electricCapacity + "kWh" : null;
                } else {
                  item.detail = null;
                }
                break;
              default:
                break;
            }
          });
        this.isDataLoaded = true;
      }
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    mainOffer(newValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
};
</script>
